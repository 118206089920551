import React from "react";

const FormWrapper = (props) => {
  return (
    <div
      className="mx-auto py-3"
      style={{
        maxWidth: 700,
      }}
    >
      {props.children}
    </div>
  );
};

export default FormWrapper;
