import { atom, useAtom } from "jotai";
import { useState } from "react";
import { selectedCompaniesAtom } from "../../../../atoms/atoms";
import dataService from "../../../../helpers/dataService";
import useFormState from "../../hooks/useFormState";

export const documentsAtom = atom(null);

const useSearchResults = () => {
  const { formData, actions, dispatch } = useFormState();
  const [selectedCompanies, setSelectedCompanies] = useAtom(
    selectedCompaniesAtom
  );
  const [documents, setDocuments] = useAtom(documentsAtom);

  const selectCompany = (company) => {
    if (!formData.selectedCompanies.find((c) => c.siret == company.siret)) {
      const newSelection = [...formData.selectedCompanies, company];
      setSelectedCompanies(newSelection);
      actions.updateFormDataValue({
        selectedCompanies: newSelection,
      });
    } else {
      unselectCompany(company);
    }
  };

  const unselectCompany = (company) => {
    const newSelection = [
      ...formData.selectedCompanies.filter((c) => c.siret != company.siret),
    ];
    setSelectedCompanies(newSelection);
    actions.updateFormDataValue({
      selectedCompanies: newSelection,
    });
  };

  //companies/imr/:siren
  const getDocuments = (company) => {
    dataService.get(
      `companies/imr/${company.siren}`,
      (data) => {
        //let newCompany = { ...company, documents: data };
        setDocuments(data);
        // actions.updateFormDataValue({
        //   selectedCompanies: [...formData.selectedCompanies.map((c) => (c.siret == company.siret ? newCompany : c))],
        // });
      },
      (err) => actions.updateErrors(err)
    );
  };

  return { formData, documents, selectCompany, unselectCompany, getDocuments };
};

export default useSearchResults;
