import React, { useEffect } from "react";
import tools from "../../../../../../helpers/tools";
import StripeBadge from "./StripeBadge";
import useCheckoutForm from "./useCheckoutForm";
import Loader from "../../../../../common/Loader";
import { useAtom } from "jotai";
import { isPriceLoadingAtom, priceAtom } from "../../../../../../atoms/atoms";

const CheckoutForm = ({ ...props }) => {
  const { pay, paymentErrors, isPaying, paymentDone, formData } =
    useCheckoutForm();
  const [price, setPrice] = useAtom(priceAtom);
  const [isPriceLoading] = useAtom(isPriceLoadingAtom);

  const selectedPMData = tools.getSelectedPMData(formData);
  const hasSub = tools.checkPMHasSub(selectedPMData);

  useEffect(() => {
    if (paymentDone === true) {
      props.onPaymentDone();
    }
  }, [paymentDone]);

  return (
    <form onSubmit={pay} className="">
      {props.children}

      {paymentErrors && (
        <div className="alert alert-danger">
          <i className="fa fa-exclamation-triangle me-2" />
          {paymentErrors}
        </div>
      )}
      {formData.paymentMode && (
        <>
          <div className="text-default my-3 d-flex " style={{ fontSize: 12 }}>
            {formData.paymentMode == "SEPA" && (
              <div>
                <div className="d-flex align-items-start mb-2">
                  <i className="fa fa-info-circle mt-1 me-2" />
                  <p className="m-0 mandate-acceptance">
                    Dans le cas d'une demande par prélèvement, la demande est
                    traitée lors de la réception du règlement,ce qui prendra
                    quelques jours. Vous serez averti par mail dès que vos
                    documents seront mis en ligne.
                  </p>
                </div>
                <div className="d-flex align-items-start">
                  <i className="fa fa-info-circle mt-1 me-2" />
                  <p className="m-0 mandate-acceptance">
                    En fournissant votre IBAN et en confirmant ce paiement, vous
                    autorisez (A) SAS Newserv. et Stripe, notre prestataire de
                    services de paiement, à envoyer des instructions à votre
                    banque pour débiter votre compte, et (B) votre banque à
                    débiter votre compte conformément à ces instructions. Vous
                    bénéficiez d’un droit à remboursement par votre banque selon
                    les conditions décrites dans la convention que vous avez
                    passée avec elle. Toute demande de remboursement doit être
                    présentée dans les 8 semaines suivant la date de débit de
                    votre compte.
                  </p>
                </div>
              </div>
            )}
            {formData.paymentMode == "CB" && (
              <>
                <i className="fa fa-info-circle mt-1 me-2" />
                <p className="m-0 mandate-acceptance">
                  En fournissant vos informations de carte bancaire, vous
                  autorisez SAS Newserv à débiter votre carte pour les paiements
                  futurs conformément à ses conditions.
                </p>
              </>
            )}
          </div>

          <div className={`d-flex justify-content-between mt-2`}>
            <button
              disabled={isPaying}
              type="submit"
              className={`btn btn-success text-white special-btn border-0 shadow-small w-100 py-2 ${
                isPaying ? "loading-btn" : ""
              }`}
              style={{
                fontWeight: "700",
                fontSize: 18,
              }}
            >
              {isPaying || isPriceLoading ? (
                <Loader
                  size={30}
                  color="white"
                  textColor="white"
                  text="Vérification en cours..."
                />
              ) : (
                <>
                  {hasSub ? (
                    "Modifier mon abonnement"
                  ) : (
                    <>
                      {`S'abonner pour ${tools.numberWithTrailingZero(
                        tools
                          .truenumberWithSpaces(
                            Math.round((price.total / 100) * 100) / 100
                          )
                          .toString()
                      )} € / mois`}
                    </>
                  )}
                </>
              )}
            </button>
          </div>
        </>
      )}
      <div className="mt-3 d-flex justify-content-end">
        <StripeBadge />
      </div>
    </form>
  );
};

export default CheckoutForm;
