import React, { useEffect } from "react";

import { toast } from "react-toastify";
import CompanyCard from "../../common/CompanyCard/CompanyCard";
import useSearchResults from "./useSearchResults";
const SearchResults = () => {
  const { formData, selectCompany } = useSearchResults();

  let isCompaniesSelected = formData.isCompaniesSelected;

  let companyList = isCompaniesSelected
    ? formData.selectedCompanies
    : formData.searchCompanyResults;

  const handleCompanyClick = (company) => {
    if (isCompaniesSelected) return;
    if (formData.selectedCompanies.length == 20) {
      toast.info("Vous ne pouvez pas sélectionner plus de 20 sociétés");
      return;
    }

    window.top.postMessage("AddToCart", "*");

    selectCompany(company);
  };

  useEffect(() => {
    if (isCompaniesSelected) {
      try {
        window.scrollTo(0, 0);
      } catch (err) {}
    }
  }, [isCompaniesSelected]);

  return (
    <div>
      {companyList.length > 0 && (
        <div className="alert alert-info">
          Sélectionnez des sociétés pour les ajouter à votre panier.
        </div>
      )}
      {companyList.map((company, ck) => {
        const isSelected = formData.selectedCompanies.find(
          (c) => c.siret == company.siret
        );
        return (
          <CompanyCard
            key={`comp${company.siret}`}
            company={company}
            clickable={!isCompaniesSelected}
            isSelected={isSelected}
            onClick={() => handleCompanyClick(company)}
            style={{ animationDelay: parseInt(ck) * 50 + "ms" }}
          >
            {/* {isCompaniesSelected && <CompanyDocuments company={company} />} */}
            {!isSelected && (
              <button className="btn text-primary px-0  py-2">
                <i className="fa fa-plus me-2" />
                Ajouter au panier
              </button>
            )}
            {isSelected && (
              <button className="btn text-success px-0  py-2">
                <i className="fa fa-check me-2" />
                Ajoutée au panier
              </button>
            )}
          </CompanyCard>
        );
      })}
    </div>
  );
};

export default SearchResults;
