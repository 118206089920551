const initialState = {
  formData: {
    searchCompanyTerms: "",
    searchCompanyResults: [],
    selectedCompanies: [],
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    passwordConfirm: "",
    isOptinChecked: false,
    paymentMode: "CB",
    existingPaymentMethods: [],
    selectedExistingPaymentMethod: "",
    billingAddresses: [],
    selectedBillingAddress: "",
    userAccountData: null, //populate lorsque le user est créé ou se connecte
  },
  formStatus: {
    isUserExists: false,
    isCheckingUserExists: false,
    isCheckedUserExists: false,
  },
  isPaymentDone: false,
  errors: {},
  navigation: {
    steps: [
      "SearchCompany",
      //  "Subscription",
      // "Account",
      "Payment",
      "Finish",
    ],
    currentStep: "SearchCompany",
  },
  loading: {
    isLoadingPayment: false,
    isLoading: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "UPDATE_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case "VALIDATE_BASKET":
      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentStep: action.payload.navigation,
        },
        formData: {
          ...state.formData,
          ...action.payload.formData,
        },
        errors: {},
      };

    case "UPDATE_STEP": {
      var other =
        action.payload == "SearchCompany"
          ? { ...state.formData, isCompaniesSelected: false }
          : { ...state.formData };
      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentStep: action.payload,
        },
        errors: {},
        formData: other,
      };
    }
    case "UPDATE_FORM_DATA_INPUT": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    }
    case "UPDATE_FORM_DATA_VALUE": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
        errors: {},
      };
    }
    case "UPDATE_FORM_STATUS_VALUE": {
      return {
        ...state,
        formStatus: {
          ...state.formStatus,
          ...action.payload,
        },
        errors: {},
      };
    }
    case "UPDATE_FORM_ERRORS": {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case "UPDATE_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
