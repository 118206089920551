import useFormState from "../../hooks/useFormState";

const ShowBasketButton = () => {
  const { formData, dispatch, formState, actions } = useFormState();
  const updateIsCompaniesSelected = () => {
    actions.updateFormDataValue({
      isCompaniesSelected: true,
    });
    actions.nextStep();
  };

  if (formData?.selectedCompanies.length == 0) return null;

  return (
    <div
      className="position-fixed bg-light w-100 d-center border-top"
      style={{
        position: "fixed",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <button
        className={`btn btn-primary btn-lg my-3 animate__animated animate__fadeIn shadow`}
        onClick={actions.validateBasket}
      >
        <span className="badge badge-primary border me-2">
          {formData.selectedCompanies.length}
        </span>
        Voir mon panier
      </button>
    </div>
  );
};

export default ShowBasketButton;
