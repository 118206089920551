import React, { useEffect } from "react";
import Loader from "react-spinners/BarLoader";
import DocumentCheck from "./DocumentCheck";
import useSearchResults from "./useSearchResults";
import Tooltip from "../../../common/Tooltip/Tooltip";

const CompanyDocuments = ({ company }) => {
  const { getDocuments, documents } = useSearchResults();

  useEffect(() => {
    getDocuments(company);
  }, [company.siret]);

  const allDocuments = [
    { code: "kbis", name: "KBIS (1/mois)" },
    { code: "annualResults", name: "Comptes annuels" },
    { code: "rbe", name: "RBE" },
    { code: "inseeSituationNotice", name: "Avis situation SIRENE" },
    { code: "inpiSituationNotice", name: "Extrait immatriculation RCS" },
    {
      code: "acts",
      name: "Actes (status, PV AGE, constitutions, modifications, etc)",
    },
  ];
  const availableDocuments = !documents
    ? []
    : allDocuments.filter((doc) => documents[doc.code]);
  const unavailableDocuments = !documents
    ? []
    : allDocuments.filter((doc) => !documents[doc.code]);

  return (
    <div className="">
      {!documents && (
        <div className="my-2 d-flex flex-column">
          <Loader />
          <small>recherche de documents</small>
        </div>
      )}
      {documents && (
        <div className="row">
          <div className="col-12 my-2">
            <div>
              <div
                className="text-success fw-bold border-success mb-1"
                style={{ fontSize: 14 }}
              >
                Documents disponibles
                <Tooltip text="Ces documents pourront être récupérés pour cette entreprise.">
                  <i className="fa fa-info-circle ms-1" />
                </Tooltip>
              </div>
              {availableDocuments.map((doc) => (
                <div className="ps-2" key={`avdoc${doc.code}`}>
                  <DocumentCheck name={doc.name} isAvailable={true} />
                </div>
              ))}
            </div>
            <div className="mt-2">
              <div
                className="text-danger fw-bold border-danger mb-1"
                style={{ fontSize: 14 }}
              >
                Documents indisponibles
                <Tooltip text="Documents indisponibles ou inexistants pour cette entreprise.">
                  <i className="fa fa-info-circle ms-1" />
                </Tooltip>
              </div>
              {unavailableDocuments.map((doc) => (
                <div className="ps-2" key={`avdoc${doc.code}`}>
                  <DocumentCheck name={doc.name} isAvailable={false} />
                </div>
              ))}{" "}
            </div>
          </div>
          {/* <div className="col-12 col-md-6">
            <div className="col-12 my-3">
              <strong className="d-block ">Documents indisponibles</strong>
              <small className="text-default">
                Documents indisponibles ou inexistants pour votre société.
              </small>
            </div>
            <div className="badge bg-danger">indisponibles</div>
            {unavailableDocuments.map((doc) => (
              <div className="" key={`avdoc${doc.code}`}>
                <DocumentCheck name={doc.name} isAvailable={false} />
              </div>
            ))}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default CompanyDocuments;
