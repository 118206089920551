import React from "react";
import tools from "../../../../helpers/tools";
import CheckCircle from "../CheckCircle/CheckCircle";

const CompanyCard = ({ company, isSelected, clickable, ...props }) => {
  return (
    <div
      className={`company-card position-relative mt-3 mb-4    ${
        clickable ? "cursor-pointer" : ""
      } animate__animated animate__fadeInUp animate__faster  ${
        isSelected ? "selected " : ""
      }
      ${clickable ? "clickable" : ""}
        `}
      {...props}
    >
      <div className="row">
        <div className="col-12">
          <div
            className="d-flex flex-row align-items-start"
            style={{
              gap: 12,
            }}
          >
            <div className="p-2 bg-light shadow-sm border rounded company-logo">
              <i className="fa fa-building" />
            </div>
            <div>
              <div>
                <strong>{company.companyName}</strong>
              </div>
              <div style={{ fontSize: 12, textTransform: "uppercase" }}>
                <div>{tools.formatSiren(company.siret)}</div>
                <div>{tools.getFullAddress(company)}</div>
                <div>
                  {company.activity} ({company.ape})
                </div>
              </div>
              <div>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
      {/* {isSelected && (
        <CheckCircle
          style={{ zIndex: "2", right: -9 }}
          animationClass="animate__fadeInDown"
        />
      )} */}
    </div>
  );
};

export default CompanyCard;
