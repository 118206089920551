import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import { promoCodeAtom } from "../../../../../../atoms/atoms";
import dataService from "../../../../../../helpers/dataService";
import useFormState from "../../../../hooks/useFormState";

const useCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { formData, actions, formStatus } = useFormState();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [errors, setErrors] = useState({});
  const [paymentErrors, setPaymentErrors] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [promoCode, setPromoCode] = useAtom(promoCodeAtom);

  const createPaymentMethod = async () => {
    const paymentSupport =
      formData.paymentMode == "CB"
        ? { type: "card", card: elements.getElement(CardNumberElement) }
        : { type: "sepa_debit", sepa_debit: elements.getElement(IbanElement) };

    // const findAddress = formData.billingAddresses.find(
    //   (addr) => addr.id == formData.selectedBillingAddress
    // );
    // const address = {
    //   city: findAddress?.city,
    //   country: "FR",
    //   line1: findAddress?.address1,
    //   line2: findAddress?.address2,
    //   postal_code: findAddress?.postalCode,
    // };

    // const billing_details = {
    //   name: `${formData.userAccountData.firstname} ${formData.userAccountData.lastname}`,
    //   email: formData.userAccountData.email,
    //   phone: formData.userAccountData.phone,
    //   address,
    // };
    let paymentMethodData = {
      ...paymentSupport,
      // billing_details,
    };

    return await stripe.createPaymentMethod(paymentMethodData);
  };

  // const setupSepaDebit = async (si) => {
  //   const iban = elements.getElement(IbanElement);

  //   const accountholderName = `${formData.userAccountData.firstname} ${formData.userAccountData.lastname}`;
  //   const email = formData.userAccountData.email;
  //   return await stripe.confirmSepaDebitSetup(si, {
  //     payment_method: {
  //       sepa_debit: iban,
  //       billing_details: {
  //         name: accountholderName,
  //         email: email,
  //       },
  //     },
  //   });
  // };

  // const attachPaymentMethod = async (paymentMethodId) => {
  //   return new Promise((resolve, reject) =>
  //     dataService.post(
  //       `user-customers/payment-methods/attach`,
  //       {
  //         paymentMethodId,
  //         userCustomerId: formStatus.basketId,
  //       },
  //       resolve,
  //       (err) => resolve({ error: err })
  //     )
  //   );
  // };

  // const getSetupIntent = async (paymentType) => {
  //   return new Promise((resolve, reject) => {
  //     dataService.post(
  //       `payments/setup-intent`,
  //       {
  //         paymentType,
  //       },
  //       resolve,
  //       (err) => resolve({ error: err })
  //     );
  //   });
  // };

  const createSubscription = async (paymentMethodId) => {
    return new Promise((resolve, reject) =>
      dataService.post(
        `payments/subscriptions`,
        {
          paymentData: {
            paymentType: formData.paymentMode,
            paymentMethodId,
            promoCode,
          },
          companies: formData.selectedCompanies,
          // customerAddress: formData.selectedBillingAddress,
          email: formData.email,
          termsOfService: formData.isOptinChecked,
          postalCode: formData.postalCode,
        },
        resolve,
        (err) => resolve({ error: err })
      )
    );
  };

  const onSubscriptionCreated = async (paymentIntentSecret) => {
    return new Promise(async (resolve, reject) => {
      if (formData.paymentMode == "CB") {
        const cardElement = elements.getElement(CardNumberElement);

        let { error, paymentIntent } = await stripe.confirmCardPayment(
          paymentIntentSecret,
          {
            payment_method: {
              card: cardElement,
              // billing_details: {
              //   name: `${formData.userAccountData.firstname} ${formData.userAccountData.lastname}`,
              // },
            },
          }
        );
        if (error) {
          resolve({ error });
        }
        //const conv = window.gtag_report_conversion("https://legadrive.fr");
        resolve(paymentIntent);
      }

      // if (formData.paymentMode == "SEPA") {
      //   const ibanElement = elements.getElement(IbanElement);

      //   let { error, paymentIntent } = await stripe.confirmSepaDebitPayment(
      //     paymentIntentSecret,
      //     {
      //       payment_method: {
      //         sepa_debit: ibanElement,
      //         billing_details: {
      //           name: `${formData.userAccountData.firstname} ${formData.userAccountData.lastname}`,
      //           email: formData.userAccountData.email,
      //         },
      //       },
      //     }
      //   );
      //   if (error) {
      //     resolve({ error });
      //   }
      //   resolve(paymentIntent);
      // }
    });
  };

  const pay = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentErrors("");

    if (formData.paymentMode != "CB" && formData.paymentMode != "SEPA") {
      return failWithError(
        "Veuillez d'abord sélectionner un mode de paiement."
      );
    }

    var resultCreatePM = null;
    var resultAttachPM = null;
    var resultCreateSub = null;
    var resultPayFirstBill = null;
    var paymentMethodId = formData.selectedExistingPaymentMethod || null;
    setIsPaying(true);

    //s'il s'agit d'une nouvelle méthode de paiement, il faut obtenir un paymentMethodId
    if (!paymentMethodId) {
      /*
      *******
      PAYMENT CB
      *******
      */
      if (formData.paymentMode == "CB") {
        resultCreatePM = await createPaymentMethod();
        if (resultCreatePM.error) {
          return failWithError(
            `La vérification de votre carte a échoué : ${
              resultCreatePM.error.message || "contactez LegaDrive"
            }`
          );
        }
        paymentMethodId = resultCreatePM.paymentMethod.id;

        // resultAttachPM = await attachPaymentMethod(paymentMethodId);
        // if (resultAttachPM.error) {
        //   return failWithError(
        //     `Une erreur est survenue lors du rattachement de la méthode de paiement à votre compte : ${
        //       resultAttachPM.error.other || "contactez LegaDrive"
        //     }`
        //   );
        // }
      }

      /*
      *******
      PAYMENT SEPA
      *******
      */
      // if (formData.paymentMode == "SEPA") {
      //   const csec = await getSetupIntent(formData.paymentMode);
      //   resultCreatePM = await setupSepaDebit(csec.setupIntent);
      //   if (resultCreatePM.error) {
      //     return failWithError(
      //       `Une erreur est survenue à la mise en place du prélèvement SEPA : ${
      //         resultCreatePM.error.message || "contactez LegaDrive"
      //       }`
      //     );
      //   }
      //   paymentMethodId = resultCreatePM.setupIntent.payment_method;
      // }
    }

    /*
    *******
    CREATION ABONNEMENT
    *******
    */
    //on crée l'abonnement
    resultCreateSub = await createSubscription(paymentMethodId);
    if (resultCreateSub.error) {
      return failWithError(
        `Une erreur est survenue lors de la création de l'abonnement : ${
          resultCreateSub.error.other || "contactez LegaDrive"
        }`
      );
    }

    /*
    *******
    CAPTURE 1ere ECHEANCE (si création)
    *******
    */
    if (resultCreateSub.paymentIntentSecret) {
      resultPayFirstBill = await onSubscriptionCreated(
        resultCreateSub.paymentIntentSecret
      );
      if (resultPayFirstBill.error) {
        return failWithError(
          `La transaction a échoué : ${
            resultPayFirstBill.error?.message || "contactez LegaDrive"
          }`
        );
      }
    }

    /*
    *******
    FINALISATION DE L'ETAPE
    *******
    */
    setIsPaying(false);
    //TOUT EST OK
    //on envoie sur l'étape de remerciement
    setPaymentDone(true);
    toast.success("Paiement accepté ✓");
  };

  const failWithError = (error) => {
    setPaymentErrors(error);
    setIsPaying(false);
    return false;
  };

  return {
    pay,
    paymentIntent,
    errors,
    paymentErrors,
    isLoading,
    isPaying,
    formData,
    paymentDone,
  };
};

export default useCheckoutForm;
