import React from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useSearchForm from "./useSearchForm";

const SearchForm = () => {
  const { handleSubmit, actions, formData, isLoading, searchDone } =
    useSearchForm();
  let companyList = formData.isCompaniesSelected
    ? formData.selectedCompanies
    : formData.searchCompanyResults;
  return (
    <form onSubmit={handleSubmit} className="row mt-4">
      <div className="col-12 col-md-8 pe-md-0">
        <Control
          label={<>Nom de l'entreprise, SIREN ou SIRET</>}
          name="searchCompanyTerms"
          value={formData.searchCompanyTerms}
          change={actions.updateFormDataInput}
          type="text"
          margin="mb-3"
        />
      </div>
      <div className="col-12 col-md-4 justify-content-end d-flex align-items-end ps-md-0">
        <SaveBtn
          disabled={!formData.searchCompanyTerms}
          className="btn btn-primary mt-2 mb-3 w-100"
          text={
            <>
              <i className="fa fa-search me-2" />
              Rechercher
            </>
          }
          isSaving={isLoading}
          typeBtn="submit"
        />
      </div>
      {companyList.length == 0 &&
        formData.searchCompanyTerms.length > 0 &&
        searchDone && (
          <div className="alert alert-info">
            Aucune société trouvée, essayez une recherche différente.
          </div>
        )}
    </form>
  );
};

export default SearchForm;
