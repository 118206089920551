import React, { useEffect } from "react";
import StepTitle from "../../common/StepTitle/StepTitle";
import useFormState from "../../hooks/useFormState";
import SelectedCompaniesWithDocuments from "../SearchCompany/SelectedCompaniesWithDocuments";
import PaymentMethodsForm from "./PaymentMethod/PaymentMethodsForm";
import PriceRecap from "./PriceRecap";
import PromoCode from "./PromoCode/PromoCode";
import usePrices from "./usePrices";
import EmailForm from "./EmailForm/EmailForm";

const Payment = () => {
  const { formData, formStatus, actions } = useFormState();
  const { getPrices, prices, unavailableCompanies, isPriceLoading } =
    usePrices();

  useEffect(() => {
    if (formData.selectedCompanies.length > 0 && formData.paymentMode) {
      getPrices();
    }
    if (formData.selectedCompanies.length === 0) {
      actions.goToStep(0);
    }
  }, [
    formData.selectedCompanies,
    formData.selectedExistingPaymentMethod,
    formData.paymentMode,
  ]);

  useEffect(() => {
    console.log("emit DisplayPayment");
    window.top.postMessage("DisplayPayment", "*");
  }, []);

  const resetSelectedCompanies = () => {
    actions.updateFormDataValue({ selectedCompanies: [] });
  };

  const allCompaniesUnavailable =
    unavailableCompanies.length == formData.selectedCompanies.length;

  return (
    <div className="d-flex flex-column flex-md-row align-items-start w-100">
      {allCompaniesUnavailable ? (
        <div className="py-3 w-100">
          <div className="alert alert-danger">
            Votre panier est vide.
            <button
              className="btn btn-primary mt-2"
              onClick={resetSelectedCompanies}
            >
              <i className="fa fa-arrow-left me-2" />
              Revenir à la sélection des sociétés
            </button>
          </div>
        </div>
      ) : (
        <div className="py-3 w-100">
          {/* <div
            className="opacity-on-hover"
            style={{
              opacity: formData.selectedBillingAddress ? "0.3" : "1",
              transition: "opacity 800ms",
            }}
          >
            <StepTitle text="Adresse de facturation" subText="" />
            <div className="mt-3">
              <BillingAddresses />
            </div>
          </div> */}
          <StepTitle text="Récapitulatif de votre commande" subText="" />
          <SelectedCompaniesWithDocuments />
          <PriceRecap />
          {/* {formData.selectedBillingAddress && ( */}
          {/* )}
          {formData.selectedBillingAddress && ( */}
          <div className="mt-4">
            <StepTitle text="Finaliser la commande" subText="" />
            <EmailForm />
            {formStatus.isCheckedUserExists && (
              <>
                <PromoCode />
                {!isPriceLoading && <PaymentMethodsForm />}
              </>
            )}
            {/* <PaymentMethod totalPrice={prices?.total || ""} /> */}
          </div>
          {/* )} */}
        </div>
      )}
    </div>
  );
};

export default Payment;
