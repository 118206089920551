import React, { useState, useEffect, useReducer, useRef } from "react";
import ControlValidIcon from "./ControlValidIcon";
import Validation from "./Validation";

const LabelContainer = ({
  labelClassname = "",
  k = -1,
  name,
  label = false,
  required = false,
  suffix = false,
  error = {},
  labelStyle = {},
  innerLabelStyle = {},
  ...props
}) => {
  const labelRef = useRef(null);

  var compareValue =
    props.value === 0 ? props.value : props.value ? props.value : [];
  return (
    <label
      ref={labelRef}
      className={`w-100 position-relative ${labelClassname} ${
        !props.isValid ? " has-error " : " "
      } 
      ${error[name] ? " has-api-error " : " "}
        `}
      htmlFor={k != -1 ? name + "-" + k : name}
      style={labelStyle}
    >
      {label && (
        <div
          className="label-text"
          style={{ paddingRight: 20, ...innerLabelStyle }}
        >
          {label}
          {required && <span className="text-danger ms-1">*</span>}
        </div>
      )}
      <div className="d-flex flex-row align-items-center mt-2">
        <div
          className={` w-100 position-relative ${props.inputGroupClassName}`}
          style={
            props.type == "checkBtnList" || props.inputGroupStyle
              ? props.inputGroupStyle
              : {}
          }
        >
          {props.children}
          {
            <ControlValidIcon
              hasError={!props.isValid}
              icon={props.validIcon}
            />
          }
        </div>
        {suffix && (
          <div
            className="input-group-append border-0 pl-2"
            style={{
              fontWeight: "500",
            }}
          >
            {suffix}
          </div>
        )}
      </div>
      {error[name] && (
        <small
          className={"form-text input-error-msg text-danger animated flash"}
          style={{
            top: 6,
            right: 8,
          }}
        >
          {error[name]}
        </small>
      )}
      {props.info && (
        <p
          className={`text-muted mt-2 mb-0 `}
          style={{
            fontWeight: "400",
          }}
        >
          {props.info}
        </p>
      )}
    </label>
  );
};

export default LabelContainer;
