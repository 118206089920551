import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import Account from "../steps/Account/Account";
import Finish from "../steps/Finish/Finish";
import Payment from "../steps/Payment/Payment";
import SearchCompany from "../steps/SearchCompany/SearchCompany";
import Subscription from "../steps/Subscription/Subscription";
import useFormState from "./useFormState";

const useCurrentComponent = () => {
  const { navigation } = useFormState();
  const stepsComponents = {
    SearchCompany: SearchCompany,
    // Subscription: Subscription,
    // Account: Account,
    Payment: Payment,
    Finish: Finish,
  };

  useEffect(() => {
    try {
      window.scrollTo(0, 0);
    } catch (err) {}
  }, [navigation.currentStep]);

  return (
    <AnimatePresence exitBeforeEnter>
      {
        navigation.steps
          .filter((step) => step == navigation.currentStep)
          .map((step, stepk) => {
            var ComponentName = stepsComponents[step];
            return (
              <motion.div
                initial={{ x: 20, opacity: 0 }}
                exit={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.2 }}
                key={`block${step}`}
              >
                <ComponentName />
              </motion.div>
            );
          })[0]
      }
    </AnimatePresence>
  );
};

export default useCurrentComponent;
