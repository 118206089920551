const CardElementContainer = ({ label, icon, children }) => {
  return (
    <label className="w-100 position-relative">
      <div className="d-flex flex-row align-items-baseline" style={{ gap: 4 }}>
        <div
          className="d-flex flex-row align-items-baseline"
          style={{ gap: 8 }}
        >
          <i className={icon} />
          <span>{label}</span>
        </div>
        <span className="text-danger ms-1">*</span>
      </div>
      {children}
    </label>
  );
};

export default CardElementContainer;
