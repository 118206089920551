import { APP_URL } from "../../../../../config";
import Control from "../../../../common/Controls/Control";
import PostalCode from "../../../../common/PostalCode";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useAccount from "../../Account/useAccount";

const EmailForm = () => {
  const {
    formData,
    actions,
    errors,
    checkUserExists,
    formStatus,
    isCheckingUserExists,
  } = useAccount();
  return (
    <div className="row mt-3 ">
      <div className="col-12 ">
        <form onSubmit={checkUserExists}>
          <Control
            label={
              <span>
                <i className="fa fa-envelope me-2" />
                Email
              </span>
            }
            type="email"
            value={formData.email}
            name="email"
            change={actions.updateFormDataInput}
            required
            error={errors}
            margin={"mb-3"}
            placeholder="Veuillez saisir votre email"
          />
          <Control
            label={
              <span>
                <i className="fa fa-map-marker me-2" />
                Code postal
              </span>
            }
            type="text"
            value={formData.postalCode}
            name="postalCode"
            change={actions.updateFormDataInput}
            required
            error={errors}
            margin={"mb-3"}
            placeholder="Veuillez saisir votre code postal"
          />
          <div className="mt-3">
            <a href={APP_URL + "/cgv.html"} target="_blank">
              Lire les conditions générales
            </a>
          </div>
          <Control
            label="J'accepte les conditions générales"
            type="checkbox"
            name="isOptinChecked"
            checked={formData.isOptinChecked}
            change={actions.updateFormDataInput}
            error={
              errors
                ? errors.termsOfService
                  ? { isOptinChecked: errors.termsOfService }
                  : errors
                : {}
            }
            margin={"mb-3"}
          />
          {formStatus.isCheckedUserExists ? null : (
            <SaveBtn
              text="Valider"
              typeBtn="submit"
              isSaving={isCheckingUserExists}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default EmailForm;
