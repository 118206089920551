import React from "react";

const DocumentCheck = ({ name, isAvailable }) => {
  return (
    <div
      className={`d-flex flex-row align-items-baseline `}
      style={{ fontSize: 12 }}
    >
      <i
        className={`fa me-2 ${
          isAvailable ? "fa-check text-success" : "fa-times text-danger"
        }`}
      />
      <span className={isAvailable ? "text-success " : "text-danger"}>
        {name}
      </span>
    </div>
  );
};

export default DocumentCheck;
