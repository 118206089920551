import React from "react";

const StripeBadge = () => {
  return (
    <div
      className="d-flex justify-content-start flex-row"
      style={{
        gap: 10,
      }}
    >
      <i className="pf pf-cb " />
      <i className="pf pf-visa " />
      <i className="pf pf-mastercard-alt " />
      <i className="pf pf-stripe " />
      {/* <img
        src="/images/stripe-badge.svg"
        style={{
          width: 100,
          height: 23,
        }}
      /> */}
    </div>
  );
};

export default StripeBadge;
