import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import useFormState from "../../hooks/useFormState";

const useAccount = () => {
  const { state, dispatch, formData, actions, formStatus, errors } =
    useFormState();
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isCheckingUserExists, setIsCheckingUserExists] = useState(false);

  const [userCreated, setUserCreated] = useState(false);

  const checkUserExists = (e) => {
    e.preventDefault();

    //vérifier si l'email saisi est valide et respecte le format email
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(formData.email)) {
      actions.updateErrors({
        email: "Veuillez saisir une adresse email valide",
      });
      return false;
    }

    //vérifier si le champ de code postal est rempli et respecte le format à 5 caractères
    if (formData.postalCode) {
      const postalCodeRegex = /^[0-9]{5}$/;
      if (!postalCodeRegex.test(formData.postalCode)) {
        actions.updateErrors({
          postalCode: "Veuillez saisir un code postal valide",
        });
        return false;
      }
    } else {
      actions.updateErrors({
        postalCode: "Veuillez saisir un code postal",
      });
      return false;
    }

    if (!formData.isOptinChecked) {
      actions.updateErrors({
        isOptinChecked: "Veuillez accepter les conditions générales",
      });
      return false;
    }

    actions.updateFormStatusValue({
      isCheckingUserExists: false,
      isCheckedUserExists: true,
    });

    // setIsCheckingUserExists(true);

    // dataService.post(
    //   `user-customers/setup`,
    //   {
    //     email: formData.email,
    //     termsOfService: formData.isOptinChecked,
    //     postalCode: formData.postalCode,
    //   },
    //   (datas) => {
    //     actions.updateFormStatusValue({
    //       isCheckingUserExists: false,
    //       isCheckedUserExists: true,
    //       basketId: datas.id,
    //     });
    //   },
    //   (err) => {
    //     actions.updateErrors(err);
    //   },
    //   () => {
    //     setIsCheckingUserExists(false);
    //   }
    // );
  };

  useEffect(() => {
    actions.updateFormStatusValue({
      isCheckingUserExists: false,
      isCheckedUserExists: false,
      isUserExists: false,
    });
    setIsCheckingUserExists(false);
  }, [formData.email, formData.isOptinChecked, formData.postalCode]);

  const createUser = (e) => {
    e.preventDefault();
    if (formData.password != formData.passwordConfirm) {
      actions.updateErrors({
        passwordConfirm: "Les mots de passes ne sont pas identiques",
      });

      return false;
    }
    setIsCreatingUser(true);
    dataService.post(
      `user-customers`,
      {
        email: formData.email,
        password: formData.password,
        firstname: formData.firstname,
        lastname: formData.lastname,
        termsOfService: formData.isOptinChecked,
      },
      (datas) => {
        actions.updateFormDataValue({
          userAccountData: datas,
        });
        setUserCreated(true);
      },
      (err) => {
        console.log("ERRRR", err);
        actions.updateErrors(err);
      },
      () => {
        setIsCreatingUser(false);
      }
    );
  };

  return {
    formData,
    actions,
    checkUserExists,
    formStatus,
    errors,
    createUser,
    isCreatingUser,
    userCreated,
    isCheckingUserExists,
  };
};

export default useAccount;
