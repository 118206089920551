import { useAtom } from "jotai";
import tools from "../../../../helpers/tools";
import { isPriceLoadingAtom, priceAtom } from "../../../../atoms/atoms";
import Loader from "../../../common/Loader";
import CheckCircle from "../../common/CheckCircle/CheckCircle";

const PriceRecap = () => {
  const [prices] = useAtom(priceAtom);
  const [isPriceLoading] = useAtom(isPriceLoadingAtom);

  return (
    <div className="d-center flex-column p-4 bg-light border shadow-sm rounded">
      {prices.totalWithoutPromo &&
        (isPriceLoading ? (
          <Loader />
        ) : (
          <>
            <div className=" mb-3">
              <div style={{ fontSize: 24 }} className="text-primary">
                <strong>Total :</strong>{" "}
                <strong
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {prices.totalWithoutPromo != prices.total && (
                    <del
                      className="me-2 text-default"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {tools.truenumberWithSpaces(
                        prices.totalWithoutPromo / 100
                      )}{" "}
                      €
                    </del>
                  )}
                  {tools.truenumberWithSpaces(prices.total / 100)} € / mois
                </strong>
              </div>
              {/* {hasSub && (
                  <div>
                    <i className="text-primary">
                      prélevés à la prochaine échéance de votre abonnement le{" "}
                      {DateTime.fromISO(
                        selectedPMData.subscription.echeance
                      ).toFormat("dd/MM/yyyy")}
                    </i>
                  </div>
                )} */}
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center my-1">
                <CheckCircle style={{ position: "initial" }} />
                <span className="ms-3 d-block">
                  Sans engagement. Résiliable à tout moment.
                </span>
              </div>
              <div className="d-flex align-items-center my-1">
                <CheckCircle style={{ position: "initial" }} />

                <span className="ms-3 d-block">
                  Vos documents professionnels mis à jour automatiquement.
                </span>
              </div>
              <div className="d-flex align-items-center my-1">
                <CheckCircle style={{ position: "initial" }} />

                <span className="ms-3 d-block">
                  Stockage sécurisé illimité de vos fichiers et partage avec les
                  tiers.
                </span>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default PriceRecap;
