import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import tools from "./helpers/tools";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderForm from "./components/OrderForm/OrderForm";
import axios from "axios";

let App = (props) => {
  var element = props.element;
  var apiKey = props.apiKey;
  axios.defaults.withCredentials = true;
  if (process.env.NODE_ENV == "development" && !props.element) {
    element = "shop-form";
    apiKey = process.env.REACT_APP_DEV_API_KEY;
  }
  console.log("apiKey", apiKey);
  var content = "";
  tools.setApiToken(apiKey);
  if (element == "shop-form") {
    content = <OrderForm {...props} />;
  }
  if (element == "default-form") {
    content = <div>Default !</div>;
  }

  return content;
};
ReactDOM.render(
  <Store rootReducer={rootReducer}>
    <App {...window.xprops} />
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  </Store>,
  document.getElementById("container")
);
