import React, { useEffect } from "react";
import NextButton from "../../common/NextButton/NextButton";
import StepTitle from "../../common/StepTitle/StepTitle";
import useFormState from "../../hooks/useFormState";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import SelectedCompaniesList from "./SelectedCompaniesList";
import { atom, useAtom } from "jotai";
import usePrices from "../Payment/usePrices";
import tools from "../../../../helpers/tools";
import CheckCircle from "../../common/CheckCircle/CheckCircle";
import Loader from "react-spinners/BarLoader";
import SelectedCompaniesWithDocuments from "./SelectedCompaniesWithDocuments";
import ShowBasketButton from "./ShowBasketButton";

const displayRecapEventSentAtom = atom(false);

const SearchCompany = () => {
  const { formData, actions } = useFormState();
  const { isCompaniesSelected } = formData;
  const [displayRecapEventSent, setDisplayRecapEventSent] = useAtom(
    displayRecapEventSentAtom
  );
  // const updateIsCompaniesSelected = () => {
  //   actions.updateFormDataValue({
  //     isCompaniesSelected: false,
  //   });
  // };

  useEffect(() => {
    if (isCompaniesSelected && !displayRecapEventSent) {
      setDisplayRecapEventSent(true);
      console.log("emit DisplayRecap");
      window.top.postMessage("DisplayRecap", "*");
    }
  }, [isCompaniesSelected]);

  // const { getPrices, prices, unavailableCompanies, isPriceLoading } =
  //   usePrices();

  // useEffect(() => {
  //   if (
  //     formData.selectedCompanies.length > 0 &&
  //     formData.paymentMode &&
  //     isCompaniesSelected
  //   )
  //     getPrices();
  // }, [
  //   formData.selectedCompanies,
  //   formData.selectedExistingPaymentMethod,
  //   formData.paymentMode,
  //   isCompaniesSelected,
  // ]);

  const stepTitleOptions = {
    text: isCompaniesSelected ? "Récapitulatif" : "Rechercher votre société",
    subText: isCompaniesSelected
      ? "Retrouvez toutes les entreprises sélectionnées ainsi que les types de documents disponibles."
      : "Depuis la barre ci-dessous, faites une recherche pour trouver l’entreprise et ajoutez-la à votre panier. ",
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-start w-100">
        <div className="py-3 pb-5 w-100">
          <StepTitle {...stepTitleOptions} />
          {!isCompaniesSelected && (
            <>
              <SearchForm />
              <SearchResults />
              <ShowBasketButton />
            </>
          )}
        </div>
      </div>
      {/* {isCompaniesSelected && (
        <div>
          <SelectedCompaniesWithDocuments />
          {prices.totalWithoutPromo &&
            (isPriceLoading ? (
              <Loader />
            ) : (
              <>
                <div className=" mb-3">
                  <div style={{ fontSize: 22 }}>
                    <strong>Total :</strong>{" "}
                    <strong>
                      {prices.totalWithoutPromo != prices.total && (
                        <del
                          className="me-2 text-default"
                          style={{
                            fontWeight: "400",
                          }}
                        >
                          {tools.truenumberWithSpaces(
                            prices.totalWithoutPromo / 100
                          )}{" "}
                          €
                        </del>
                      )}
                      {tools.truenumberWithSpaces(prices.total / 100)} €/mois
                    </strong>
                  </div>
                   {hasSub && (
                    <div>
                      <i className="text-primary">
                        prélevés à la prochaine échéance de votre abonnement le{" "}
                        {DateTime.fromISO(
                          selectedPMData.subscription.echeance
                        ).toFormat("dd/MM/yyyy")}
                      </i>
                    </div>
                  )} 
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex align-items-center my-1">
                    <CheckCircle style={{ position: "initial" }} />
                    <span className="ms-3 d-block">
                      Sans engagement. Résiliable à tout moment.
                    </span>
                  </div>
                  <div className="d-flex align-items-center my-1">
                    <CheckCircle style={{ position: "initial" }} />

                    <span className="ms-3 d-block">
                      Vos documents professionnels mis à jour automatiquement.
                    </span>
                  </div>
                  <div className="d-flex align-items-center my-1">
                    <CheckCircle style={{ position: "initial" }} />

                    <span className="ms-3 d-block">
                      Stockage sécurisé illimité de vos fichiers et partage avec
                      les tiers.
                    </span>
                  </div>
                </div>
              </>
            ))}
          <div className="d-flex justify-content-end">
            <NextButton fullWidth={false} />
          </div>
        </div>
      )} */}
    </>
  );
};

export default SearchCompany;
