import { Provider } from "jotai";
import CompanyCard from "../../common/CompanyCard/CompanyCard";
import CompanyDocuments from "./CompanyDocuments";
import useSearchResults from "./useSearchResults";

const SelectedCompaniesWithDocuments = () => {
  const { formData, unselectCompany } = useSearchResults();

  return formData.selectedCompanies.map((company, ck) => (
    <CompanyCard
      key={`comp${company.siret}`}
      company={company}
      clickable={false}
      isSelected={true}
      onClick={() => {}}
      style={{ animationDelay: parseInt(ck) * 50 + "ms" }}
    >
      <Provider>
        <CompanyDocuments company={company} />
      </Provider>
      <button
        className="btn btn-small bg-light border shadow-sm text-dark mt-2"
        onClick={() => unselectCompany(company)}
        style={{ fontSize: 12 }}
      >
        <i className="fa fa-trash me-2" />
        Retirer du panier
      </button>
    </CompanyCard>
  ));
};

export default SelectedCompaniesWithDocuments;
