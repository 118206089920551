import React from "react";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import usePrices from "../usePrices";

const PromoCode = () => {
  const {
    getPrices,
    prices,
    promoCode,
    setPromoCode,
    promoCodeError,
    isPriceLoading,
    isPromoCodeValid,
    removePromoCode,
  } = usePrices();

  return (
    <div className="row ">
      <div className="col-12">
        {!isPromoCodeValid && (
          <Control
            label="Si vous avez un code promo, renseignez le ici"
            placeholder="Code promo"
            type="text"
            name="promoCode"
            value={promoCode}
            change={(e) =>
              setPromoCode(e.target.value.toUpperCase().split(" ").join(""))
            }
            error={promoCodeError}
            margin={"mb-3"}
          />
        )}
        {promoCode && !isPromoCodeValid && (
          <SaveBtn
            type="secondary"
            text="Appliquer le code promo"
            className="mb-3"
            save={(e) => getPrices()}
            isSaving={isPriceLoading}
          />
        )}
        {isPromoCodeValid && (
          <div className="mt-3">
            <div className="text-success">
              <i className="fa fa-check text-success me-2" />
              Code promo appliqué
            </div>
            <button
              className="btn btn-link text-default"
              onClick={removePromoCode}
            >
              Retirer le code promo
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoCode;
