import React from "react";
import useFormState from "../../hooks/useFormState";

const DisplayStep = () => {
  const { navigation } = useFormState();

  const currentStep = navigation.steps.indexOf(navigation.currentStep) + 1;
  const totalStep = navigation.steps.length - 1;

  return (
    <div className="">
      ÉTAPE <strong>{currentStep}</strong> SUR <strong>{totalStep}</strong>
    </div>
  );
};

export default DisplayStep;
