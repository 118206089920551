import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Loader from "react-spinners/BarLoader";
import { constantActions } from "../../context/actions/constantActions";
import { AUTH_TYPES } from "../../context/reducers/authReducers";
import tools from "../../helpers/tools";
import FormWrapper from "./common/FormWrapper/FormWrapper";
import PreviousButton from "./common/PreviousButton/PreviousButton";
import StepWrapper from "./common/StepWrapper/StepWrapper";
import useAuth from "./hooks/useAuth";
import useCurrentComponent from "./hooks/useCurrentComponent";
import useFormState from "./hooks/useFormState";
import useRestore from "./hooks/useRestore";

const OrderForm = (props) => {
  const { state, dispatch, constants } = useFormState();
  const { refreshToken } = useAuth();
  const currentComponent = useCurrentComponent();
  const const_actions = constantActions(state, dispatch);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { restoreFormState } = useRestore();

  useEffect(() => {
    if (props.authToken && !state.auth.isAuthenticated) {
      localStorage.setItem("jwtToken", props.authToken);
      // Set authToken to Auth header
      tools.setAuthToken(props.authToken);
      //refreshToken();
      dispatch({
        type: AUTH_TYPES.SET_CURRENT_USER,
        payload: jwt_decode(props.authToken),
      });
    }
  }, [props.authToken]);

  useEffect(() => {
    console.log("STATE", state);
    if (isFirstLoad && state.auth.isAuthenticated) {
      refreshToken();
      setIsFirstLoad(false);
    }
  }, [state]);
  useEffect(() => {
    const_actions.getConstants();
    restoreFormState();
  }, []);

  const constantsLoaded = Object.values(constants).length > 0;

  return constantsLoaded ? (
    <FormWrapper>
      {/* <Navigation /> */}
      <StepWrapper>
        <PreviousButton />
        {currentComponent}
      </StepWrapper>
    </FormWrapper>
  ) : (
    <Loader />
  );
};

export default OrderForm;
