import React from "react";

const StepWrapper = (props) => {
  return (
    <div className="py-4 px-3 px-md-5 rounded position-relative">
      {props.children}
    </div>
  );
};

export default StepWrapper;
